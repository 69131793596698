<!--
// nuxt-ui/components/Shared/stats/CardStatsTurnMvp.vue
-->
<script setup lang="ts">
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import PlayerPlaceholderKL from "~/nuxt-ui/assets/images/kl-player-placeholder.png";
import PlayerPlaceholderQL from "~/nuxt-ui/assets/images/ql-player-placeholder.png";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { setAliasUrl } from "~/src/Shared/utils";

type Props = {
  tableStatTitle: string;
  hasPartner?: boolean;
  partnerLogo?: string;
  partnerName?: string;
  featuredPlayerImage: string | undefined;
  featuredPlayerName: string | undefined;
  featuredPlayerTeamImage: string | undefined | null;
  featuredPlayerStat: number | string | null;
  turnNumber: number | string | null;
  pageStatsLink: string;
  hideTurn?: boolean;
  hideStat?: boolean;
};

defineProps<Props>();

const localePath = useLocalePath();
const { regionParam, isRegionHome } = useRegions();
const { t } = useI18n();
const route = useRoute();
const queries = computed(() => {
  return route.query ? route.query : undefined;
});
const { isKings } = useWebContext();
const placeholderImage = isKings ? PlayerPlaceholderKL : PlayerPlaceholderQL;

const { locale } = useI18n();

const setTurnTitle = turnName => {
  const turnNameLength = turnName.split(" ").length;
  const lastTurnContent = turnName.split(" ")[turnNameLength - 1];

  if (turnNameLength > 0 && isNaN(Number(lastTurnContent))) return t(`turnMvp.turn.${lastTurnContent}`);
  else if (turnNameLength > 0) return lastTurnContent;
  return undefined;
};

const videoBg = ref<HTMLVideoElement | null>(null);

const playVideoBg = () => {
  if ("play" in videoBg.value) {
    videoBg.value.play();
  }
};

const pauseVideoBg = () => {
  if ("pause" in videoBg.value) {
    videoBg.value.pause();
  }
};
</script>

<template>
  <div class="flex flex-col max-w-[343px] min-w-full w-full lg:max-w-none 2xl:w-auto 2xl:aspect-[325/568]">
    <nuxt-link
      :to="
        localePath({
          name: `region-stats-${pageStatsLink}`,
          params: { region: regionParam },
          query: queries,
        })
      "
      class="card-player-image-container"
      @mouseover="playVideoBg"
      @mouseout="pauseVideoBg"
      :no-prefetch="isRegionHome ? true : undefined"
    >
      <video
        :src="isKings ? '/img/stats/bg-turn-mvp-KL.webm' : '/img/stats/bg-turn-mvp-QL.webm'"
        :poster="isKings ? '/img/stats/bg-turn-mvp-kl-fixed.jpg' : '/img/stats/bg-turn-mvp-ql-fixed.jpg'"
        class="absolute top-0 left-0 right-0 bottom-0 min-h-[450px] object-cover object-top"
        ref="videoBg"
        width="100%"
        height="100%"
        loop
        muted
      ></video>
      <div class="image-container">
        <NuxtImg
          v-if="featuredPlayerImage"
          :src="featuredPlayerImage ? setAliasUrl(featuredPlayerImage) : placeholderImage"
          :alt="featuredPlayerName || ''"
          class="object-top object-cover min-h-[450px]"
          width="456"
          height="642"
          sizes="256px sm:472px md:325px"
        />
      </div>
      <div class="gradient-container">
        <div class="h-full flex flex-col justify-start gap-3">
          <div v-if="!hideTurn" class="min-w-min min-h-fit flex justify-start items-start gap-1.5">
            <div class="flex items-start justify-start w-[15px]">
              <span v-if="!isNaN(Number(setTurnTitle(turnNumber)))" class="turn-text">{{
                $t("cards.stats.matchWeek.text")
              }}</span>
              <span v-else class="turn-text">{{ setTurnTitle(turnNumber) }}</span>
            </div>
            <p v-if="!isNaN(Number(setTurnTitle(turnNumber)))" class="text-primary text-3xl font-bold -mt-1">
              {{ setTurnTitle(turnNumber) }}
            </p>
          </div>
          <div v-else class="min-w-min min-h-fit flex justify-start items-start gap-1.5">
            <div class="flex items-start justify-start w-[15px]">
              <span class="turn-text">{{ $t("general.match.word") }}</span>
            </div>
          </div>

          <div class="text-primary">
            <svg width="52" height="219" viewBox="0 0 52 219" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M51.9967 218.796L0 218.796L-1.08057e-06 194.075L22.5974 184.902C23.5043 184.488 24.6631 184.047 26.0739 183.581C27.4847 183.062 28.9458 182.57 30.4573 182.104C31.9185 181.585 33.2285 181.119 34.3873 180.704L34.3873 180.16C33.3293 179.849 32.0948 179.461 30.6841 178.994C29.2733 178.476 27.8626 177.958 26.4518 177.439C24.9906 176.869 23.7058 176.377 22.5974 175.962L-2.27328e-06 166.789L-3.32327e-06 142.768L51.9967 142.768L51.9967 158.549L25.167 158.549C24.1089 158.549 22.9249 158.549 21.6149 158.549C20.3049 158.497 19.0201 158.446 17.7605 158.394C16.5009 158.342 15.4176 158.316 14.5107 158.316L14.5107 158.938C15.468 159.249 16.6016 159.638 17.9116 160.104C19.2216 160.57 20.5316 161.037 21.8416 161.503C23.1012 161.918 24.2097 162.332 25.167 162.747L51.9967 173.941L51.9967 188.4L25.167 199.672C23.857 200.191 22.5218 200.709 21.1614 201.227C19.8011 201.694 18.5414 202.134 17.3826 202.549C16.1734 202.912 15.2161 203.222 14.5107 203.482L14.5107 204.103C15.2161 204.052 16.1482 204.026 17.307 204.026C18.4659 203.974 19.7255 203.948 21.0859 203.948C22.4462 203.896 23.8066 203.87 25.167 203.87L51.9967 203.87L51.9967 218.796Z"
                fill="currentColor"
              />
              <path
                d="M51.9967 109.385L-3.65776e-06 135.116L-4.42571e-06 117.547L25.3181 105.887C26.2754 105.369 27.4847 104.824 28.9458 104.254C30.3566 103.684 31.7925 103.088 33.2537 102.466C34.7148 101.844 36.0248 101.326 37.1837 100.912L37.1837 100.367C36.1256 99.9528 34.8912 99.4864 33.4804 98.9681C32.0696 98.3981 30.6589 97.8021 29.2481 97.1802C27.787 96.5583 26.477 95.9882 25.3181 95.4699L-5.90725e-06 83.6538L-6.64462e-06 66.7847L51.9967 92.5936L51.9967 109.385Z"
                fill="currentColor"
              />
              <path
                d="M51.9967 59.2211L-6.97524e-06 59.2211L-8.77619e-06 18.0201C-8.93023e-06 14.496 0.755756 11.4384 2.26728 8.84709C3.77882 6.20401 5.86977 4.15692 8.54014 2.70581C11.1601 1.20288 14.2084 0.451431 17.6849 0.451431C21.1614 0.45143 24.2601 1.20288 26.9808 2.70581C29.6512 4.20874 31.7421 6.28174 33.2537 8.92482C34.7148 11.5679 35.4454 14.6515 35.4454 18.1756L35.4454 43.4404L51.9967 43.4404L51.9967 59.2211ZM24.0333 43.4404L24.0333 22.5289C24.0333 20.1968 23.5043 18.4347 22.4462 17.2428C21.3378 15.999 19.7759 15.3771 17.7605 15.3771C16.4001 15.3771 15.2665 15.6621 14.3595 16.2322C13.4022 16.8022 12.6717 17.6055 12.1678 18.642C11.664 19.6785 11.4121 20.9742 11.4121 22.5289L11.4121 43.4404L24.0333 43.4404Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        <div class="flex justify-end items-end">
          <NuxtImg
            v-if="hasPartner"
            :src="setAliasUrl(partnerLogo)"
            :alt="partnerName || ''"
            width="59"
            height="21"
            class="h-5 max-h-[21px] object-contain"
          />
        </div>
      </div>
    </nuxt-link>
    <div class="mt-4 table-first-stats">
      <div class="flex items-center justify-between text-gray-400 text-[10px] uppercase pl-2 pr-1">
        <span class="w-4/6 text-left">{{ $t("cards.stats.name.text") }}</span>
        <span class="w-1/6 text-center ml-auto">{{ $t("cards.stats.team.text") }}</span>
        <span v-if="!hideStat" class="w-1/6 text-center">{{ tableStatTitle }}</span>
      </div>
      <div
        class="mt-2 flex items-center justify-between text-sm font-bold bg-gray-800 pl-2 pr-1 h-14 rounded-md"
      >
        <div class="w-4/6 text-left">{{ featuredPlayerName }}</div>
        <div class="w-1/6 logo-team-container">
          <NuxtImg
            v-if="featuredPlayerTeamImage"
            :src="setAliasUrl(featuredPlayerTeamImage)"
            alt="Team"
            width="44"
            height="44"
            class="object-contain"
          />
        </div>
        <div v-if="!hideStat" class="w-1/6 text-center">{{ featuredPlayerStat }}</div>
      </div>
    </div>
    <div class="text-center pt-2 pb-1.5" v-if="regionParam && pageStatsLink">
      <nuxt-link
        :to="
          localePath({
            name: `region-stats-${pageStatsLink}`,
            params: { region: regionParam },
            query: queries,
          })
        "
        class="see-more-link"
        :no-prefetch="isRegionHome ? true : undefined"
      >
        {{ $t("cards.stats.seeMore.link") }}
      </nuxt-link>
    </div>
  </div>
</template>

<style scoped>
.card-player-image-container {
  @apply min-h-[450px] px-1 py-2 rounded-lg relative overflow-hidden bg-no-repeat bg-cover bg-center;
}

.image-container {
  @apply h-full absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-start items-center pt-5;
}

.gradient-container {
  @apply absolute top-0 right-0 left-0 bottom-0 bg-[linear-gradient(180deg,rgba(0,0,0,0.00)68.26%,rgba(0,0,0,0.80)100%)] px-1 py-2 flex justify-between;
}

.turn-text {
  @apply text-primary text-xs uppercase font-bold leading-[15px] rotate-180;
  writing-mode: vertical-lr;
}

.logo-team-container {
  @apply h-8 w-8 lg:w-11 lg:h-11 flex flex-col justify-center items-center;
}

.see-more-link {
  @apply text-xs text-gray-400;
}
</style>
